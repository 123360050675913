@mixin font-one {
  font-family: "Poppins", sans-serif;
}

@mixin font-two {
  font-family: "Fjalla One", sans-serif;
}

@mixin font-three {
  font-family: "Dancing Script", cursive;
}

@mixin font-render {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}

@mixin transition {
  transition: 0.25s ease-in-out;
}

@mixin transition-cubic {
  transition: all 0.5s ease;
  transition-duration: 700ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

@mixin transition-cubic-second {
  transition: all 0.3s ease;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}

@mixin blur {
  filter: blur(25px);
}

@mixin no-blur {
  filter: blur(0);
}

body {
  margin: 0;
  padding: 0;
  @include font-render;
  background: url("./../img/maze/maze3.svg");
  background-size: 200px;
  overflow-x: hidden;
}

main.page-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  section {
    width: 100%;
  }
}

section {
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.custom-link {
  display: block;
  transition: all 0.5s;

  * {
    @include transition;
  }

  a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: $color-secondary;

    &:hover {
      text-decoration: none;
    }
  }

  b {
    font-weight: 600;
    display: inline-block;
    margin-left: 20px;
  }

  .lines {
    width: 42px;
    height: 1px;
    float: left;
    overflow: hidden;

    span {
      &:nth-child(1) {
        width: 100%;
        height: 1px;
        float: right;
        background: $color-secondary;
      }

      &:nth-child(2) {
        width: 0;
        height: 1px;
        float: left;
        background: $color-secondary;
      }
    }
  }

  &:hover {
    .lines {
      width: 62px;
      margin-left: 20px;

      span {
        &:nth-child(1) {
          width: 0;
        }

        &:nth-child(2) {
          width: 100%;
          transition-delay: 0.2s;
        }
      }
    }
  }
}

.slide-btn {
  display: block;
  transition: all 0.5s;
  padding-left: 30px;

  a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 14px;
    color: #fff;

    &:hover {
      text-decoration: none;
    }
  }

  b {
    font-weight: 600;
  }

  .lines {
    width: 42px;
    height: 1px;
    float: left;
    overflow: hidden;
    @include transition;

    span {
      &:nth-child(1) {
        width: 100%;
        height: 1px;
        float: right;
        background: #fff;
        @include transition;
      }

      &:nth-child(2) {
        width: 0;
        height: 1px;
        float: left;
        background: #fff;
        @include transition;
      }
    }
  }

  .video-play-circle {
    stroke-dasharray: 315;
    stroke-dashoffset: 315;
    transition: stroke-dashoffset 0.5s;
  }

  svg {
    width: 50px;
    height: 50px;
    transition: transform 0.3s;
    margin-left: -25px;
    margin-right: 15px;
  }

  &:hover {
    .lines {
      width: 62px;
      margin-left: 20px;

      span {
        &:nth-child(1) {
          width: 0;
        }

        &:nth-child(2) {
          width: 100%;
          transition-delay: 0.2s;
        }
      }
    }

    .video-play-circle {
      stroke-dashoffset: 0;
    }
  }
}

.swiper-slider {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  overflow: hidden;
  .swiper-slide {
    display: block;
    overflow: hidden;
    &.swiper-slide-active .slide-inner {
      transform: scale(1);
      .container {
        transform: translateY(0);
        @include transition-cubic;
        opacity: 1;
      }
    }
    .slide-inner {
      width: 100%;
      height: 100%;
      transform: scale(0.8);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      @include transition-cubic;
      background-size: cover;
      background-position: center;
      position: relative;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        background: url(../img/bg-noise.gif);
        opacity: 0.04;
        position: absolute;
        left: 0;
        top: 0;
      }
      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url(../img/bg-lines.png) center;
        z-index: 1;
      }
      .container {
        transform: translateY(50px);
        opacity: 0;
        transition-delay: 0.15s !important;
        position: relative;
        z-index: 2;
        .tagline {
          display: block;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 15px;
          span {
            color: $color-secondary;
            float: left;
            font-weight: 600;
            color: #fff;
            &:after {
              content: "";
              width: 22px;
              height: 1px;
              float: right;
              margin: 10px;
              background: $color-secondary;
            }
          }
          h6 {
            float: left;
            @include font-two;
            opacity: 0.7;
            margin-top: 2px;
            margin-bottom: 0;
          }
        }
        h1 {
          width: 100%;
          float: left;
          font-weight: 800;
          color: $color-secondary;
          font-size: 10vw;
          line-height: 1;
          margin: 0;
          span {
            -webkit-text-stroke-width: 1px;
            transform: translateY(-35%);
            margin-left: 4%;
            display: flex;
            -webkit-text-stroke-color: #fff;
            color: transparent;
          }
        }
        .slide-btn {
          float: left;
        }
      }
    }
  }
  .swiper-pagination-bullets {
    width: 50px;
    left: auto;
    right: 40px;
    bottom: 50%;
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    z-index: 2 !important;
    transform: translateY(50%);

    .swiper-pagination-bullet {
      width: 100%;
      height: auto;
      float: left;
      margin: 5px 0;
      background: none;
      position: relative;
      text-indent: 9999px;
      padding-right: 36px;
      opacity: 0.4;
      @include transition;
      &:hover {
        opacity: 1;
      }
      &.swiper-pagination-bullet-active {
        text-indent: 0;
        opacity: 1;
        &:after {
          width: 67px;
          right: -45px;
        }
      }
      &:after {
        content: "";
        width: 22px;
        height: 1px;
        background: $color-secondary;
        position: absolute;
        top: 10px;
        right: 0;
        @include transition;
      }
    }
  }
}

/* INTRO */
.brabus-intro-wrapper {
  width: 100%;
  position: relative;
}

.intro {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.intro h6 {
  display: block;
  line-height: 1;
  margin-bottom: 20px;
  font-family: "Fjalla One", sans-serif;
  opacity: 0.5;
  font-size: 14px;
}
.intro h2 {
  margin-bottom: 60px;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.2;
  padding-right: 25%;
  position: relative;
}
.intro h2:before {
  content: attr(data-text);
  font-size: 20vw;
  opacity: 0.05;
  z-index: -1;
  position: absolute;
  left: 0;
  top: -20%;
}
.intro h4 {
  margin-top: 5px;
  font-family: "Fjalla One", sans-serif;
  font-size: 25px;
  line-height: 40px;
  text-transform: uppercase;
}
.intro p {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 37px;
}
.intro u {
  text-decoration: none;
  border-bottom: 3px dotted #e8293b;
  font-weight: 600;
}
.intro strong {
  font-weight: 600;
}
.intro .custom-link {
  transform: translateY(30px);
}

/* SERVICES CONTENT BLOCK */
.services-content-block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: $color-primary;
  color: #fff;
  position: relative;
  .video-bg,
  .youtube-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    opacity: 0.2;
    video {
      min-width: 100%;
      min-height: 100%;
      float: left;
    }
  }
  h6 {
    display: block;
    line-height: 1;
    margin-bottom: 20px;
    @include font-two;
    font-size: 14px;
  }
  h2 {
    margin-bottom: 60px;
    font-weight: 800;
    font-size: 60px;
    line-height: 1.2;
    padding-right: 35%;
  }
  .left {
    width: 100px;
    float: left;
    margin-top: -2px;
  }
  .right {
    width: calc(100% - 100px);
    float: left;
  }
  small {
    display: inline-block;
    @include font-two;
    color: $color-secondary;
  }
  span {
    width: 50px;
    height: 1px;
    background: $color-secondary;
    display: inline-block;
    margin: 5px 15px;
  }
  h4 {
    @include font-two;
    font-size: 17px;
    margin-bottom: 30px;
  }
  ul {
    padding-left: 20px;
    margin-bottom: 0;
    li {
      line-height: 1.8;
    }
  }

  .content-box {
    overflow: hidden;
    margin-bottom: 40px;
  }
}

/* WORK WITH US */
.work-with-us {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  background: $color-primary;
  position: relative;

  .custom-link {
    a {
      color: $color-secondary;
    }

    .lines span {
      background: $color-secondary;
    }
  }
}
.work-with-us:before {
  content: "";
  width: 100%;
  height: 100%;
  background: url(../img/bg-noise.gif);
  opacity: 0.04;
  position: absolute;
  left: 0;
  top: 0;
}
.work-with-us:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(../img/bg-lines.png) center;
  z-index: 1;
}
.work-with-us .container {
  position: relative;
  z-index: 2;
}
.work-with-us h6 {
  display: block;
  line-height: 1;
  margin-bottom: 20px;
  font-family: "Fjalla One", sans-serif;
  font-size: 14px;
  color: $color-secondary;
}
.work-with-us h2 {
  margin-bottom: 60px;
  font-weight: 800;
  font-size: 60px;
  line-height: 1.2;
  padding-right: 35%;
  color: $color-white;
}
.work-with-us h2:before {
  content: attr(data-text);
  font-size: 20vw;
  opacity: 0.05;
  z-index: -1;
  position: absolute;
  left: 0;
  top: -20%;
}
.work-with-us h4 {
  margin-top: 5px;
  font-family: "Fjalla One", sans-serif;
  font-size: 25px;
  line-height: 40px;
  text-transform: uppercase;
  padding-right: 20%;
  margin-bottom: 40px;
  color: $color-white;
}

/* SERVICES */

.service-tile {
  padding: 10px;
  text-align: center;

  &:hover {
    img {
      transform: scale(1.2);
    }
  }

  img {
    width: 50%;
    height: auto;
    margin: auto;
    transition: 300ms;
  }

  h4 {
    font-size: 20px;
    line-height: 26px;
    margin-top: 30px;
  }
}

/* Partners */

.partners {
  h2 {
    padding-right: 0;
    margin-bottom: 10px;
  }

  .tile {
    position: relative;
    padding-top: 15px;
    padding-bottom: 15px;

    * {
      transition: 200ms ease-in-out;
    }

    img {
      max-width: 100%;
      opacity: 1;
      background: #fff;
      border: 1px solid #eee;
      padding: 10px;
    }

    small {
      width: 100%;
      height: 50px;
      line-height: 50px;
      position: absolute;
      left: 0;
      top: 50%;
      text-align: center;
      font-size: 600;
      opacity: 0;
      font-family: "Fjalla One", sans-serif;
      font-size: 22px;
    }

    &:hover small {
      margin-top: -25px;
      opacity: 1;
    }

    &:hover img {
      opacity: 0.05;
      transform: scale(1.2);
    }
  }

  .client-item {
    display: block;
    color: $color-black;
  }
}

/* ERROR 404 */
.error-404 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  text-align: center;
  img {
    display: inline-block;
    margin-bottom: 40px;
  }
  p {
    font-size: 19px;
    margin-bottom: 30px;
  }
  a {
    color: $color-secondary;

    &:hover {
      text-decoration: none;
    }
  }
}

/* MEDIUM FIXES */
@media only screen and (max-width: 1199px),
  only screen and (max-device-width: 1199px) {
  .swiper-slider .swiper-slide .slide-inner {
    padding: 0 10%;
  }
  .intro h4 {
    padding-right: 0;
  }
  .work-with-us h2 {
    padding-right: 0;
  }
  .work-with-us h4 {
    padding-right: 0;
  }
  .works-single h2 {
    padding-right: 0;
  }
  .direction h2 {
    padding-right: 0;
  }
  .clients h4 {
    padding-right: 0;
  }
  .news .post {
    flex-direction: column;
  }
  .news .post .post-image {
    margin-bottom: 30px;
  }
  .direction figure figcaption {
    width: 50%;
  }
  .hello h2 {
    padding-right: 0;
  }
  .blog .widget-area {
    margin: 0;
    padding: 0;
  }
}

/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px),
  only screen and (max-device-width: 991px) {
  header {
    min-height: inherit;
  }
  .swiper-slider .swiper-slide .slide-inner .container h1 {
    font-size: 12vw;
  }
  .intro {
    padding: 80px 0;
  }
  .intro h2 {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .intro .custom-link {
    transform: translateY(0);
  }
  .process {
    padding: 80px 0;
  }
  .process h2 {
    margin-bottom: 0;
  }
  .services-content-block {
    padding: 80px 0;
  }
  .services-content-block h2 {
    padding-right: 0;
  }
  .services-content-block .left {
    width: 100%;
    margin-bottom: 20px;
  }
  .services-content-block .right {
    width: 100%;
  }
  .team {
    padding: 80px 0;
  }
  .team figure {
    margin: 15px 0;
  }
  .direction {
    padding: 80px 0;
  }
  .direction figure figcaption {
    width: 100%;
  }
  .works {
    padding: 80px 0;
  }
  .works h2 {
    padding-right: 0;
    margin-bottom: 50px;
  }
  .works .project-box {
    padding: 35px 0;
  }
  .work-with-us {
    padding: 80px 0;
  }
  .works-single {
    padding: 80px 0;
  }
  .news {
    padding: 30px 0;
    padding-bottom: 80px;
  }
  .news .post.single-post {
    padding-left: 0;
    padding-right: 0;
  }
  .news .post.single-post .post-content .inner .post-title {
    font-size: 44px;
  }
  .news .post:nth-child(2n + 2) .post-image {
    order: 0;
  }
  .news .post:nth-child(2n + 2) .post-content {
    padding: 0 50px;
  }
  .awards {
    padding: 80px 0;
  }
  .clients {
    padding: 80px 0;
  }
  .hello {
    padding: 80px 0;
  }
  .hello #contact {
    margin-left: 0;
    box-shadow: none;
  }
  .footer .footer-quote h2 {
    font-size: 6vw;
  }
}

/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px),
  only screen and (max-device-width: 767px) {
  .container {
    padding: 0 30px;
  }
  .page-header .video-bg:before {
    display: none;
  }
  header .scroll-down {
    left: -10px;
  }
  header .sound {
    right: 30px;
    bottom: 30px;
  }
  .header {
    min-height: inherit;
  }
  .page-header {
    min-height: inherit;
  }
  .page-header .container h1 {
    font-size: 10vw;
  }
  .navigation-menu.active .bg-layers span {
    width: 50%;
  }
  .navigation-menu.active .bg-layers span:nth-child(1) {
    left: -50%;
    width: 50%;
  }
  .navigation-menu.active .bg-layers span:nth-child(2) {
    left: 0;
    width: 50%;
  }
  .navigation-menu.active .bg-layers span:nth-child(3) {
    left: 50%;
    width: 50%;
  }
  .navigation-menu.active .bg-layers span:nth-child(4) {
    left: 100%;
    width: 50%;
  }
  .navigation-menu .inner .menu ul li {
    display: block;
    margin-bottom: 20px;
  }
  .navigation-menu .inner .menu ul li a {
    font-size: 9vw;
    padding-bottom: 2px;
  }
  .navigation-menu .inner .menu ul li a:hover {
    padding-bottom: 2px;
  }
  .navigation-menu .inner .menu ul li ul {
    position: static;
    display: none;
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
    margin-bottom: 0;
  }
  .navigation-menu .inner .menu ul li ul li {
    padding: 5px 0;
  }
  .navigation-menu .inner .menu ul li ul li a {
    font-size: 20px;
  }
  //.navigation-menu .inner .menu ul li:hover ul{display: block;}
  .navigation-menu .inner blockquote {
    display: none;
  }
  .navbar {
    padding: 20px 30px;
  }
  .navbar .right ul {
    display: none;
  }
  .navbar .right .hamburger-menu {
    margin-left: auto;
  }
  header .left-side {
    display: none;
  }
  .header .container h1 {
    font-size: 20vw;
  }
  .header .container h1 span {
    margin-left: 0;
    transform: translateY(0);
  }
  .header .container .slide-btn {
    padding-left: 0;
  }
  .swiper-slider .swiper-slide .slide-inner {
    padding: 0;
  }
  .swiper-slider .swiper-pagination-bullets {
    display: none;
  }
  .swiper-slider .swiper-slide .slide-inner:before {
    display: none;
  }
  .swiper-slider .swiper-slide .slide-inner .container h1 {
    font-size: 15vw;
  }
  .swiper-slider .swiper-slide .slide-inner .container h1 span {
    margin-left: 0;
    transform: translateY(0);
  }
  .swiper-slider .swiper-slide .slide-inner .container .slide-btn {
    padding-left: 0;
  }
  .awards {
    padding: 40px 0;
    padding-bottom: 0;
  }
  .awards .odometer {
    margin-bottom: 40px;
  }
  .works-single h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .works-single h4 {
    margin-bottom: 20px;
  }
  .works-single p {
    font-size: 18px;
    line-height: 30px;
  }
  .hello h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .hello address {
    margin-bottom: 20px;
  }
  .hello iframe {
    height: 400px !important;
  }
  .hello #contact {
    width: auto;
    margin-top: 40px;
    padding: 0;
    margin-left: -15px;
  }
  .team h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .intro h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .intro h4 {
    margin-bottom: 20px;
  }
  .intro p {
    font-size: 18px;
    line-height: 30px;
  }
  .services-content-block h2 {
    font-size: 32px;
    margin-bottom: 60px;
  }
  .direction h2 {
    font-size: 32px;
    margin-bottom: 40px;
  }
  .direction figure figcaption {
    width: 100%;
    position: static;
    display: block;
  }
  .works h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .works .project-box {
    flex-direction: column;
  }
  .works .project-box figure {
    width: 100%;
    margin-bottom: 20px;
  }
  .works .project-box:nth-child(2n + 2) figure {
    order: 0;
  }
  .works .project-box:nth-child(2n + 2) .content-box {
    text-align: left;
  }
  .works .project-box:nth-child(2n + 2) .custom-link a {
    float: left;
  }
  .works .project-box .content-box {
    width: 100%;
    padding-left: 0;
  }
  .works .project-box .content-box .inner small {
    margin-bottom: 5px;
  }
  .works .project-box .content-box .inner h3 {
    margin-bottom: 30px;
  }
  .works .project-box .content-box .inner h3 span {
    margin-left: 0;
    font-size: 12vw;
  }
  .services-content-block .col-md-4:nth-child(3) {
    margin: 40px 0;
  }
  .work-with-us h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .clients h2 {
    font-size: 32px;
    margin-bottom: 20px;
  }
  .clients ul {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 40px;
    width: auto;
  }
  .clients ul li {
    width: 50%;
  }
  .news .pagination {
    margin-top: 0;
    text-align: left;
  }
  .news .pagination a {
    margin-left: 0;
    margin-right: 20px;
  }
  .news .post .post-content {
    padding: 0;
  }
  .news .post:nth-child(2n + 2) .post-content {
    padding: 0;
  }
  .footer {
    padding: 80px 0;
  }
  .footer .footer-quote img {
    margin-bottom: 40px;
  }
  .footer .footer-quote {
    margin-bottom: 40px;
  }
  .footer .footer-quote h2 {
    font-size: 8vw;
  }
  .footer .footer-contact {
    margin-bottom: 40px;
  }
  .footer .footer-contact address {
    margin-bottom: 30px;
  }
  .footer .footer-bottom ul li {
    margin-right: 15px;
  }

  .service-tile img {
    max-width: 70px;
  }
}

//Responsive menu
@media (max-width: 767px) {
  html {
    body {
      .navigation-menu {
        .brabus-nav {
          overflow-y: scroll;
          height: calc(100vh - 96px);
          display: flex !important;
          justify-content: center;
          flex-direction: column;
          > .menu {
            li {
              ul {
                visibility: visible;
                opacity: 1;
                display: none;

                &.dropdown {
                  &.active {
                    display: block;
                    visibility: visible;
                    opacity: 1;
                  }

                  li {
                    margin: 0 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .horizontal-nav-menu-wrapper {
    display: none !important;
  }
  .horizontal-nav-menu-mobile {
    display: block !important;
    text-align: right;
  }
}
@media (min-width: 768px) {
  .horizontal-nav-menu-mobile {
    display: none !important;
  }
}

.header {
  width: 100%;
  height: 100vh;
  min-height: 660px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: $color-gray;
  .container {
    position: relative;
    z-index: 2;
    .tagline {
      display: block;
      color: #fff;
      text-transform: uppercase;
      margin-bottom: 15px;
      span {
        color: $color-primary;
        float: left;
        font-weight: 600;
        color: #fff;
        &:after {
          content: "";
          width: 22px;
          height: 1px;
          float: right;
          margin: 10px;
          background: $color-primary;
        }
      }
      h6 {
        float: left;
        @include font-two;
        opacity: 0.5;
        margin-top: 2px;
        margin-bottom: 0;
      }
    }
    h1 {
      width: 100%;
      float: left;
      font-weight: 800;
      color: $color-primary;
      font-size: 10vw;
      line-height: 1;
      span {
        -webkit-text-stroke-width: 1px;
        transform: translateY(-35%);
        margin-left: 4%;
        display: flex;
        -webkit-text-stroke-color: #fff;
        color: transparent;
      }
    }
    .slide-btn {
      float: left;
    }
  }
  .perspective {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
    canvas {
      min-height: 100%;
      width: auto !important;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .particles-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
    .particles {
      width: 100%;
      height: calc(100% - 50px);
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
  .video-bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: url(../img/bg-lines.png) center;
      z-index: 2;
    }
    .vc_video-bg {
      transform: scale(1.1);
    }
    video {
      min-width: 100%;
      min-height: 100%;
      position: absolute;
      opacity: 0.8;
    }
  }
}
.page-header {
  width: 100%;
  height: 100vh;
  min-height: 660px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $color-black;
  overflow: hidden;
  .video-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: url(../img/bg-lines.png) center;
      z-index: 2;
    }
    video {
      min-width: 100%;
      min-height: 100%;
      float: left;
      opacity: 0.2;
    }
  }
  .container {
    position: relative;
    z-index: 3;
    padding: 0 10%;
    h1 {
      font-weight: 800;
      font-size: 10vw;
      color: #fff;
      line-height: 1;
      text-indent: -5px;
      text-transform: uppercase;
    }
    h2 {
      font-weight: 600;
      color: #fff;
      line-height: 1;
      text-indent: -5px;
    }
    p {
      margin-bottom: 0;
      @include font-two;
      color: $color-secondary;
    }
  }
}
header {
  transform: scale(1.5);
  @include transition-cubic;
  .left-side {
    width: 50%;
    height: 60px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    margin-left: 30px;
    z-index: 3;
    ul {
      margin: 0 auto;
      padding: 0;
      li {
        display: inline-block;
        margin: 0 10px;
        padding: 0;
        list-style: none;
        a {
          font-size: 14px;
          color: #fff;
          @include font-two;
          @include transition;
          padding-bottom: 6px;
          border-bottom: 2px solid transparent;
          &:hover {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
  .scroll-down {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 3;
  }
  .scroll-down small {
    width: 98px;
    display: inline-block;
    transform: rotate(-90deg);
    margin-bottom: 50px;
    color: #fff;
    @include font-two;
  }
  .scroll-down span {
    display: block;
    height: 50px;
    position: relative;
    transform: translateX(-1px);
  }
  .scroll-down span::before {
    -webkit-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
    -moz-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
    -o-animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
    animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
  }
  .scroll-down span::before {
    position: absolute;
    top: 0px;
    left: 50%;
    margin-left: -0.5px;
    width: 1px;
    height: 70px;
    background: #fff;
    content: "";
  }
  .sound {
    position: absolute;
    right: 40px;
    bottom: 40px;
    z-index: 3;
    span {
      float: left;
      color: #fff;
      font-size: 14px;
      margin-right: 10px;
      @include font-two;
    }
    .equalizer {
      float: left;
      .holder {
        width: 23px;
        height: 20px;
        position: relative;
      }
    }
  }
}

.navbar {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px 40px;
  color: #fff;
  @include font-two;
  font-size: 14px;
  z-index: 10;
  transform: translateY(-130%);
  @include transition-cubic;
  a {
    color: #fff;
  }
  .left,
  .right {
    width: 30%;
    margin-left: 0;
    ul {
      margin-left: auto;
      margin-bottom: 0;
      padding: 0;
      text-transform: uppercase;

      li {
        display: inline-block;
        margin-left: 15px;
        padding: 0;
        a {
          position: relative;
          padding-bottom: 6px;
          @include transition;
          border-bottom: 2px solid transparent;
          &:hover {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            text-decoration: none;
          }
        }
      }
    }
    a {
      position: relative;
      padding-bottom: 6px;
      @include transition;
      border-bottom: 2px solid transparent;
      &:hover {
        padding-bottom: 1px;
        border-bottom: 2px solid #fff;
        text-decoration: none;
      }
    }
  }
  .logo {
    margin: 0 auto;
    a {
      float: left;
      img {
        height: 56px;
      }
    }
  }
  .right {
    width: 30%;
    margin-right: 0;
    text-align: right;
    ul {
      margin-left: auto;
      margin-bottom: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-left: 15px;
        padding: 0;
        a {
          position: relative;
          padding-bottom: 6px;
          @include transition;
          border-bottom: 2px solid transparent;
          &:hover {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            text-decoration: none;
          }
        }
      }
    }
    .hamburger-menu {
      margin-right: 0;
      margin-left: 60px;
      float: right;
      cursor: pointer;

      .hamburger {
        float: right;
        margin-top: 1px;
      }

      b {
        display: inline-block;
        font-weight: 400;
        margin-right: 15px;
      }
    }
  }
}

// Breakpoints

$bp-xsmall: 499px;
$bp-small: 768px;
$bp-medium: 920px;
$bp-large: 1024px;
$bp-xlarge: 1200px;

// Colors

$color-navy: #273b69;
$color-orange: #ea5b0c;
$color-gray: #3c3c3b;
$color-white: #fff;
$color-black: #000;

$color-primary: $color-navy;
$color-secondary: $color-orange;

.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

.bg-primary {
  color: $color-primary !important;
}

.bg-secondary {
  color: $color-secondary !important;
}

.footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 60px;
  background: $color-primary;
  color: #fff;

  a {
    color: #fff;
  }

  .footer-quote {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    img {
      height: 35px;
      display: block;
    }

    h2 {
      font-size: 45px;
      font-weight: 800;
      margin-bottom: 20px;
      color: $color-white;

      @media screen and (max-width: $bp-medium) {
        font-size: 40px;
      }

      @media screen and (max-width: $bp-small) {
        font-size: 30px;
      }
    }

    .footer-cta {
      @include font-two;
      text-transform: uppercase;
      border-bottom: 1px solid #fff;
      padding-bottom: 3px;

      &:hover {
        text-decoration: none;
        color: $color-secondary;
        border-color: $color-secondary;
      }
    }
  }

  .footer-contact {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    address {
      margin-bottom: 0;
    }

    a {
      color: #fff;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .footer-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;

    p {
      margin: 0;
      margin-top: 30px;
      opacity: 0.5;
      font-size: 13px;
    }

    h5 {
      margin-bottom: 10px;
      @include font-two;
      text-transform: uppercase;
      color: $color-white;
    }

    ul {
      display: block;
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        margin: 0;
        margin-right: 20px;
        padding: 0;
        list-style: none;

        &:last-child {
          margin-right: 0;
        }

        a {
          position: relative;
          color: #fff;
          font-size: 14px;
          font-weight: 600;
          padding-bottom: 6px;
          @include transition;
          border-bottom: 2px solid transparent;

          &:hover {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

/* NAVBAR HORIZONTAL */
.navbar.horizontal {
  display: flex;
  .logo {
    margin-left: 0;
  }
  .horizontal-nav-menu-wrapper {
    margin-left: auto;
    ul {
      margin-left: auto;
      margin-bottom: 0;
      padding: 0;
      li {
        display: inline-block;
        margin-left: 15px;
        padding: 0;
        position: relative;
        &:hover ul {
          @media (min-width: 768px) {
            display: block;
            ul {
              display: none;
            }
          }
        }
        a {
          position: relative;
          padding-bottom: 6px;
          @include transition;
          border-bottom: 2px solid transparent;
          text-transform: uppercase;
          &:hover {
            padding-bottom: 1px;
            border-bottom: 2px solid #fff;
            text-decoration: none;
          }
        }
      }
      ul {
        background: $color-secondary;
        position: absolute;
        left: 0;
        top: 100%;
        margin-top: 10px;
        white-space: nowrap;
        display: none;
        padding: 5px 0;
        &.sub-menu {
          ul {
            &.sub-menu {
              &:before {
                content: none;
              }
            }
          }
        }
        &:before {
          content: "";
          width: 100%;
          height: 10px;
          position: absolute;
          left: 0;
          top: -10px;
        }
        li {
          width: 100%;
          display: block;
          margin: 0;
          padding: 5px 15px;
          &:hover > ul {
            display: block;
          }
          //ul{margin: 0; top: -5px; left: 100%; background: #fff;
          ul {
            margin: 0;
            left: 15px;
            background: #fff;
            &:before {
              content: "";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 7px 7px 7px 0;
              border-color: transparent #fff transparent transparent;
              position: absolute;
              left: -7px;
              top: 10px;
            }
          }
          a {
            color: $color-black;
            border-color: transparent;
            &:hover {
              border-color: transparent;
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
  .language {
    margin-left: 60px;
    margin-bottom: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-left: 15px;
      padding: 0;
      a {
        position: relative;
        padding-bottom: 6px;
        @include transition;
        border-bottom: 2px solid transparent;
        &:hover {
          padding-bottom: 1px;
          border-bottom: 2px solid #fff;
          text-decoration: none;
        }
      }
    }
  }
}

/* NAVBAR LOGO LEFT */
.hamburger_left {
  display: flex;
  .logo {
    margin-left: 0;
  }
}

.navigation-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9;
  visibility: hidden;
  .bg-layers {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 0;
    top: 0;
    * {
      @include transition-cubic;
    }
    span {
      width: 0%;
      height: 100%;
      position: absolute;
      top: 0;
      transform: skewX(0);
      background: $color-black;
      &:nth-child(1) {
        left: 0;
        &:before {
          content: "";
          width: 150%;
          height: 100%;
          background: $color-black;
          position: absolute;
          left: -149%;
          top: 0;
        }
      }
      &:nth-child(2) {
        left: 25%;
      }
      &:nth-child(3) {
        left: 50%;
      }
      &:nth-child(4) {
        left: 75%;
        &:before {
          content: "";
          width: 150%;
          height: 100%;
          background: $color-black;
          position: absolute;
          right: -149%;
          top: 0;
        }
      }
    }
  }
  .inner {
    position: relative;
    z-index: 3;
    color: #fff;
    text-align: center;
    padding-top: 96px;
    * {
      @include transition;
    }
    .menu {
      display: block;
      min-height: 130px;
      opacity: 0;
      transform: translateY(-30px);
      ul {
        display: block;
        margin-bottom: 50px;
        padding: 0;
        position: relative;
        li {
          display: inline-block;
          margin: 0 20px;
          padding: 0;
          list-style: none;
          @media (min-width: 768px) {
            &:hover ul {
              opacity: 1;
              visibility: visible;
            }
            &:hover ul li ul {
              opacity: 0;
              visibility: hidden;
            }
          }
          a {
            display: inline-block;
            line-height: 1;
            font-size: 3vw;
            font-weight: 800;
            color: #fff;
            padding-bottom: 8px;
            border-bottom: 5px solid transparent;
            &:hover {
              padding-bottom: 3px;
              border-bottom: 5px solid $color-secondary;
              text-decoration: none;
            }
          }
          ul {
            width: 100%;
            min-height: inherit;
            position: absolute;
            left: 0;
            top: 70px;
            opacity: 0;
            visibility: hidden;
            li {
              margin: 0 15px;
              a {
                font-size: 2vw;
                padding: 0;
                border-bottom: none;
                &:hover {
                  padding: 0;
                  border-bottom: none;
                  color: $color-secondary;
                }
              }
              @media (min-width: 768px) {
                &:hover ul {
                  opacity: 1;
                  visibility: visible;
                }
              }
              ul {
                top: 55px;
                opacity: 0;
                visibility: hidden;
                li {
                  margin: 0 20px;
                  a {
                    font-size: 20px;
                    font-weight: 600;
                  }
                }
              }
            }
          }
        }
      }
    }
    blockquote {
      display: block;
      font-size: 2vw;
      @include font-two;
      text-transform: uppercase;
      color: $color-secondary;
      opacity: 0;
      transition-delay: 0.5s;
      transform: translateY(30px);
      a {
        display: block;
        color: #fff;
      }
    }
  }
  &.active .bg-layers {
    visibility: visible;
  }
  &.active .bg-layers span {
    width: calc(25% + 1px);
    transform: skewX(45deg);
  }
  &.active .bg-layers span:nth-child(1) {
    width: calc(25% + 1px);
  }
  &.active .bg-layers span:nth-child(4) {
    width: calc(25% + 1px);
  }
  &.active .inner .menu {
    opacity: 1;
    transform: translateY(0);
  }
  &.active .inner blockquote {
    opacity: 1;
    transform: translateY(0);
  }
  &.active {
    visibility: visible;
  }
}

.hamburger {
  float: left;
  width: 26px;
  height: 18px;
  position: relative;
  * {
    @include transition;
  }
  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    background: $color-secondary;
    z-index: 0;
    &:nth-child(1) {
      top: 0px;
      width: 50%;
    }
    &:nth-child(2) {
      top: 8px;
    }
    &:nth-child(3) {
      width: 50%;
      top: 16px;
      right: 0;
      left: auto;
    }
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      top: 0;
      height: 100%;
      width: 0;
      right: 0;
      left: auto;
    }
    &:before {
      z-index: 1;
      background: #fff;
      transition: all 0.15s linear 0s;
    }
    &:after {
      z-index: 2;
      background: $color-secondary;
      transition: all 0.15s linear 0.3s;
    }
    &:nth-child(2):before {
      transition-delay: 0.05s;
    }
    &:nth-child(3):before {
      transition-delay: 0.1s;
    }
    &:nth-child(2):after {
      transition-delay: 0.15s;
    }
    &:nth-child(3):after {
      transition-delay: 0.2s;
    }
  }
}
.hamburger:hover {
  span {
    width: 100% !important;
    &:after,
    &:before {
      width: 100%;
      left: 0;
      right: auto;
    }
    &:after {
      z-index: 1;
      transition: all 0.15s linear 0s;
    }
    &:before {
      z-index: 2;
      transition: all 0.15s linear 0.3s;
    }
    &:nth-child(2):after {
      transition-delay: 0.05s;
    }
    &:nth-child(3):after {
      transition-delay: 0.1s;
    }
    &:nth-child(2):before {
      transition-delay: 0.15s;
    }
    &:nth-child(3):before {
      transition-delay: 0.2s;
    }
  }
}

.hamburger.open span:nth-child(1) {
  top: 9px;
  width: 100%;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
.hamburger.open span:nth-child(2) {
  opacity: 0;
  left: -15px;
}
.hamburger.open span:nth-child(3) {
  top: 9px;
  width: 100%;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
